
import PropTypes from 'prop-types';
import { forwardRef, useCallback, useRef, createContext, useContext, cloneElement } from 'react';
import { assignRef } from 'common/utils';

const DropdownContext = createContext(null);
DropdownContext.displayName = 'DropdownContext';

export function useDropdownContext () {
  return useContext(DropdownContext);
}

import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import useMemoObject from '@twipped/hooks/useMemoObject';
import useToggledState from '@twipped/hooks/useToggledState';

const DropdownItem = forwardRef(function DropdownMenuItem ({ eventKey, onClick, ...props }, ref) {
  const { onSelect, close } = useDropdownContext();

  const handleClick = useCallback((ev) => {
    onClick && onClick(ev, eventKey);
    if (ev.defaultPrevented) return;
    close();
    onSelect && onSelect(eventKey);
  }, [ eventKey, onClick, onSelect ]);

  return <MenuItem {...props} ref={ref} onClick={handleClick} />;
});
DropdownItem.displayName = 'DropdownItem';
DropdownItem.propTypes = MenuItem.propTypes;


const DropdownMenu = forwardRef(function DropdownMenu ({ onSelect, target, ...props }, ref) {

  const { state: isOpen, off: close, toggle } = useToggledState(false);
  const targetRef = useRef();

  const context = useMemoObject({
    isOpen,
    close,
    onSelect,
  });

  return (
    <DropdownContext.Provider value={context}>
      {cloneElement(target, {
        onClick: toggle,
        ref: useCallback((node) => {
          assignRef(target.ref, node);
          assignRef(targetRef, node);
        }, [ target, targetRef ]),
      })}
      <Menu
        {...props}
        anchorEl={targetRef.current}
        keepMounted
        open={isOpen}
        onClose={close}
        ref={ref}
      />
    </DropdownContext.Provider>
  );
});
DropdownMenu.displayName = 'DropdownMenu';
DropdownMenu.Item = DropdownItem;
DropdownMenu.propTypes = {
  ...Menu.propTypes,
  open: PropTypes.any,
};
export default DropdownMenu;

