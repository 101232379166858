import AbstractGC from './abstract';

export default class Order extends AbstractGC {

  async current() {
    return await this.get('order');
  }

  async getParts(orderid) {
    return await this.get(`order/parts?orderid=${orderid}`);
  }

  async getOrders() {
    return await this.get(`vendor/orders`)
  }

  async getOrderDetail(orderid) {
    return await this.get(`order/details?orderid=${orderid}`);
  }

  async getVendorOrderDetail(orderid) {
    return await this.get(`vendor/order/details/${orderid}`);
  }

  async acceptOrder(partId) {
    const form_data = new FormData();
    form_data.append('partid', partId);
    return await this.post(`vendor/accept-order`, form_data);
  }

  async declineOrder(partId) {
    const form_data = new FormData();
    form_data.append('partid', partId);
    return await this.post(`vendor/decline-order`, form_data);
  }

  async updateOrder(body) {
    return await this.post(`order/update`, body);
  }

  async getDetails(orderid) {
    return await this.get(`order/details?orderid=${orderid}`);
  }

  async getOrderTags() {
    return await this.get(`ordertags`);
  }

  async addOrderTags(tag) {
    const form_data = new FormData();
    form_data.append('orderid', tag.orderid);
    form_data.append('tagid', tag.tagid);
    return await this.post(`order/add-tag`, form_data);
  }

  async removeOrderTags(tag) {
    const form_data = new FormData();
    form_data.append('orderid', tag.orderid);
    form_data.append('tagid', tag.tagid);
    return await this.post(`order/remove-tag`, form_data);
  }

  async getPartDownloadLink(partid) {
    return await this.get(`/vendor/completeddocument/${partid}`, { timeout: 300 * 1000 });
  }

  async getTermsDetails() {
    return await this.get(`vendor/terms/details`);
  }

  async acceptGuidelines(guidelineid) {
    return await this.post(`vendor/accept-guidelines?guidelineid=${guidelineid}`);
  }

  async declineGuidelines(guidelineid) {
    return await this.post(`vendor/decline-guidelines?guidelineid=${guidelineid}`);
  }
  
  async PreliminaryOrderCount(){
    return await this.get(`/order/preliminaryCount`);
  }

  async getAvailableLawFirms(orderid) {
    return await this.get(`/order/lawfirms?orderid=${orderid}`);
  }

  async assignOrderPartToLawFirm(partid, firmid) {
    const form_data = new FormData();
    form_data.append('partid', partid);
    form_data.append('firmid', firmid);
    return await this.post(`order/assign-lawfirm`, form_data);
  }

  async unAssignLawFirm(partid) {
    return await this.post(`/order/unassign-lawfirm/${partid}`)
  }

  async unAssignOrderPartLawFirm(partid) {
    const form_data = new FormData();
    form_data.append('partid', partid);
    return await this.post(`order/unassign-lawfirm`,form_data);
  }

}
