import { useCallback, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Popover from '@mui/material/Popover';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import CancelIcon from '@mui/icons-material/Cancel';
import CircularProgress from '@mui/material/CircularProgress';
import useGlobalSearch from '../../useGlobalsearch';
import debounce from 'lodash.debounce';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { format } from 'date-fns'
import { styled } from '@mui/material/styles';
import { css } from '@mui/system';
import { useNavigate, useLocation } from 'react-router-dom';

const SearchBox = styled(Box)(
  ({ theme }) => (css`
    min-width: 920px;
    &:before {
      content: "";
      background-color: ${theme.palette.primary.main};
      display: block;
      position: absolute;
      top: 3px;
      left: 25%;
      width: 18px;
      height: 18px;
      transform: translateY(-50%) rotate(45deg);
      z-index: 0;
    }
  `),
);

const SearchResultBox = styled(Box)(
  ({ theme }) => (css`
    background-color: ${theme.palette.primary.main};
    color: white;
    padding: 12px 16px;
  `)
);

const TableContainer = styled(Box)(css`
  padding: 12px 16px;

`);

const TableTitle = styled('h5')(
  ({ theme }) => (css`
    color: ${theme.palette.primary.main};
    margin: 0;
  `)
);

const TableHeadStyled = styled(TableHead)(css`
  th {
    padding: 6px 3px;
    font-weight: bold;
  }
`);

const TableCellStyled = styled(TableCell)(css`
  padding: 9px 2px;
  font-size: 14px;
  cursor: default;
  border-color: #EFEFEF;
`);

const SearchPopover = styled(Popover)(css`
  .MuiPopover-paper {
    overflow: visible;
    top: 64px !important;
  }
`);
export default function GlobalSearch ({ sx }) {
  const [anchorEl, setAnchorEl ] = useState(null);
  const [searchresults, setSearchresults] = useState(null);
  const [searchKeyword, setSearchKeyword] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const searchInput = useRef(null);

  var loading = false;

  var globalsearch = new useGlobalSearch();

  const searchHandler = useCallback( async (event) => {

    if(event.target.value.length < 3)
    {
      setSearchKeyword(null)
      return;
    }

    setAnchorEl(event.target);
    var _searchresults = await globalsearch.search(event.target.value);

    if(_searchresults === null)
    {

    }
    else
    {
      //sort search results by weight
      _searchresults.sort( (a,b) => {
        return (a.old ? 1 : 0) - (b.old ? 1 : 0) ||
        b.weight - a.weight ||
        b.order_status - a.order_status ||
        b.ordereddate_ts - a.ordereddate_ts
      });
      setSearchresults(_searchresults);
      setSearchKeyword(event.target.value)
    }

    loading = true;

  });

  const onkeyup = useCallback(debounce(searchHandler,1000),[]);

  const clearSearchKeyword = () => {
    searchInput.current.value = "";
    setSearchKeyword(null)
  }

  const handleClose = () => {
    setAnchorEl(null);
  }; 

  const onClickRow = (row) => {
    let searchParam;
    if (row.type == 'order') {
      searchParam = new URLSearchParams({'orderDetail': row.id});
      navigate('?' + searchParam.toString());
    } else {
      searchParam = new URLSearchParams({'id': row.id});
      navigate('vendors?' + searchParam.toString());
    } 

    handleClose();
  }

  const hightlightSearchKeywords = (content) => {
    if (!searchKeyword || !content)
      return content;

    const pos = content.toLowerCase().indexOf(searchKeyword.toLowerCase());
    if (pos < 0)
      return content

    return (
      <Typography variant="span">
        {content.substr(0, pos)}
        <Typography variant="span" sx={{background: 'yellow', fontWeight: 'bold'}}>{content.substr(pos, searchKeyword.length)}</Typography>
        {content.substr(pos+searchKeyword.length)}
      </Typography>
    )
  }

  const renderSearchResults = () => {

    if (searchresults === undefined || searchresults === null || searchresults.length == 0)
    {
      return null;
    }
    else
    {
      var toporderresults = searchresults.filter( (item) => { return item.type === "order"; })
      if (toporderresults.length > 5) toporderresults = toporderresults.slice(0, 5)
      var topvendorresults = searchresults.filter( (item) => { return item.type === "vendor"; })
      if (topvendorresults.length > 5) topvendorresults = topvendorresults.slice(0, 5)

      return (
        <TableContainer>
          <TableTitle>
            Orders
          </TableTitle>
          <Table sx={{ marginLeft: '8px' }}>
            <TableHeadStyled>
              <TableRow>
                <TableCell>Order Date</TableCell>
                <TableCell>Client</TableCell>
                <TableCell>Address</TableCell>
                <TableCell>City</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Escrow ID</TableCell>
                <TableCell>Loan Reference</TableCell>
              </TableRow>
            </TableHeadStyled>
            <TableBody>
              {toporderresults.map( (item, index) => {
                return <TableRow hover
                    key={'order'+index}
                    onClick={() => onClickRow(item)}
                  >
                    <TableCellStyled>{item.ordereddate && format(new Date(item.ordereddate), 'MM/dd/yyyy')}</TableCellStyled>
                    <TableCellStyled>{hightlightSearchKeywords(item.clientname)}</TableCellStyled>
                    <TableCellStyled>{hightlightSearchKeywords(item.propertyaddress)}</TableCellStyled>
                    <TableCellStyled>{hightlightSearchKeywords(item.propertycity)}</TableCellStyled>
                    <TableCellStyled>{hightlightSearchKeywords(item.order_status_descrip)}</TableCellStyled>
                    <TableCellStyled>{hightlightSearchKeywords(item.quandis_valuation_id)}</TableCellStyled>
                    <TableCellStyled>{hightlightSearchKeywords(item.loanreference)}</TableCellStyled>
                  </TableRow>
              })}
            </TableBody>
          </Table>
          <TableTitle sx={{marginTop: '12px'}}>
            Vendors
          </TableTitle>
          <Table sx={{ marginLeft: '8px' }}>
            <TableHeadStyled>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Firm/Company</TableCell>
                <TableCell>Phones</TableCell>
              </TableRow>
            </TableHeadStyled>
            <TableBody>
              {topvendorresults.map( (item, index) => {
                return <TableRow hover
                    key={'vendor'+index}
                    onClick={() => onClickRow(item)}
                  >
                    <TableCellStyled>{hightlightSearchKeywords(item.firstname + ' ' + item.lastname)}</TableCellStyled>
                    <TableCellStyled>{hightlightSearchKeywords(item.email)}</TableCellStyled>
                    <TableCellStyled>{hightlightSearchKeywords(item.company)}</TableCellStyled>
                    <TableCellStyled>{hightlightSearchKeywords(item.phone)}</TableCellStyled>
                  </TableRow>
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )
    }
  }

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <Paper
        component="form"
        sx={{ p: '2px 4px', display: 'flex', alignItems: 'center',  boxShadow:'rgb(0 0 0 / 5%) 0px 1px 1px 0px',...sx }}
      >
        <IconButton sx={{ p: '10px' }} aria-label="menu">
          <SearchIcon />
        </IconButton>
        <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          placeholder="Search by address, escrow or loan reference number"
          inputProps={{ 'aria-label': 'search orders' }}
          onKeyUp={onkeyup}
          inputRef={searchInput}
        />
        <IconButton
          sx={{padding: '5px', color: '#a8a8a8'}}
          aria-label="directions"
          onClick={clearSearchKeyword}
        >
          <CancelIcon sx={{width: '18px'}}/>
        </IconButton>
        {loading && <CircularProgress />}
      </Paper>
      <SearchPopover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        disableAutoFocus
        disableEnforceFocus
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <SearchBox>
          <SearchResultBox>
            Search Results
          </SearchResultBox>
          {renderSearchResults()}
        </SearchBox>
      </SearchPopover>
    </>
  );
}
GlobalSearch.propTypes = {
  sx: PropTypes.object,
};

