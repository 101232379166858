import { observable, computed, action, toJS, makeObservable } from "mobx";
import { collect, get, set, jsonSoftParse, merge, find } from 'common/utils';
import { parseJSON as parseDate } from 'date-fns';

export default class PipelineRecord {

  constructor(initial) {
    this._properties = parseRecord(initial);

    makeObservable(this, {
      _properties: observable,
      id: computed,
      lock: action,
      unlock: action,
      set: action,
      merge: action,
      partA: computed,
      partB: computed,
      partC: computed,
      inspection: computed,
      json: computed,
    });
  }

  get id() {
    return this._properties.id;
  }

  get $() {
    return this._properties;
  }

  lock(lock) {
    this._properties.locked = lock;
    return this;
  }

  unlock() {
    this._properties.locked = null;
    return this;
  }

  get(...args) {
    return get(this._properties, ...args);
  }

  collect(...args) {
    return collect(this._properties, ...args);
  }

  set(path, value) {
    set(this._properties, path, value);
    return this;
  }

  merge(data) {
    data = parseRecord(data);
    // this._properties = merge(this._properties, data);
    this._properties = data;
    return this;
  }

  get partA() {
    return this._properties.parts && find(this.parts, (p) =>
      p.label === 'A' &&
      [1, 5, 6, 12, 13, 16].includes(p.type.id),
    ) || null;
  }

  get partB() {
    return this._properties.parts && find(this.parts, (p) =>
      p.label === 'B' &&
      [1, 12, 13, 16].includes(p.type.id),
    ) || null;
  }

  get partC() {
    return this._properties.parts && find(this.parts, (p) =>
      p.label === 'C' &&
      [1, 12].includes(p.type.id),
    ) || null;
  }

  get inspection() {
    return this._properties.parts && find(this.parts, (p) => [2, 3, 14].includes(p.type.id), ) || null;
  }

  get json() {
    return {
      ...toJS(this._properties),
      partA: toJS(this.partA),
      partB: toJS(this.partB),
      partC: toJS(this.partC),
      inspection: toJS(this.inspection),
    };
  }

}

export function parseRecord(input) {
  input = jsonSoftParse(input);
  if (input.date) {
    input.date.due = input.date.due && parseDate(input.date.due) || null;
    input.date.ordered = input.date.ordered && parseDate(input.date.ordered) || null;
    input.date.effective = input.date.effective && parseDate(input.date.effective) || null;
    input.date.last_touched = input.date.last_touched && parseDate(input.date.last_touched) || null;
    input.date.completed = input.date.completed && parseDate(input.date.completed) || null;
    input.date.transaction = input.date.transaction && parseDate(input.date.transaction) || null;
    input.date.started = input.date.started && parseDate(input.date.started) || null;
    input.date.submitted = input.date.submitted && parseDate(input.date.submitted) || null;
    input.date.paid = input.date.paid && parseDate(input.date.paid) || null;
    input.date.updated = input.date.updated && parseDate(input.date.updated) || null;
    input.date.canceled = input.date.canceled && parseDate(input.date.canceled) || null;
    input.date.inspection = input.date.inspection && parseDate(input.date.inspection) || null;
    input.date.due_original = input.date.due_original && parseDate(input.date.due_original) || null;
    input.date.rejected     = input.date.rejected    && parseDate(input.date.rejected) || null;
  }

  if (input.checklist && input.checklist.date) input.checklist.date = parseDate(input.checklist.date) || null;
  if (input.last_log && input.last_log.date) input.last_log.date = parseDate(input.last_log.date) || null;

  input.parts = input.parts && input.parts.map((part) => {
    if (part.assigned && part.assigned.date_assigned) part.assigned.date_assigned = parseDate(part.assigned.date_assigned) || null;
    if (part.agent && part.agent.date_accepted) part.agent.date_accepted = parseDate(part.agent.date_accepted) || null;

    if (part.date) {
      part.date.due = part.date.due && parseDate(part.date.due) || null;
      part.date.started = part.date.started && parseDate(part.date.started) || null;
      part.date.submitted = part.date.submitted && parseDate(part.date.submitted) || null;
      part.date.effective = part.date.effective && parseDate(part.date.effective) || null;
      part.date.paid = part.date.paid && parseDate(part.date.paid) || null;
      part.date.updated = part.date.updated && parseDate(part.date.updated) || null;
      part.date.canceled = part.date.canceled && parseDate(part.date.canceled) || null;
      part.date.inspection.set = part.date.inspection.set && parseDate(part.date.inspection.set) || null;
      part.date.inspection.created = part.date.inspection.created && parseDate(part.date.inspection.created) || null;
      part.date.inspection.performed = part.date.inspection.performed && parseDate(part.date.inspection.performed) || null;
      part.date.inspection.reviewed = part.date.inspection.reviewed && parseDate(part.date.inspection.reviewed) || null;
    }
    return part;
  }) || [];

  return input;
}
