

import { observable, action, makeObservable } from "mobx";
import { map, warn } from 'common/utils';
import PipelineRecord from './pipeline-record';

export default class DataChannel {

  constructor (name, companyid, initial = []) {
    const records = map(initial, (row) => {
      const record = new PipelineRecord(row);
      return [ record.id, record ];
    });
    this.name = name;
    this.companyid = companyid;
    this.records = observable.map(records);
    this.refreshing = true;

    makeObservable(this, {
      name: observable,
      records: observable,
      refreshing: observable,
      bind: action,
      refresh: action,
      _reset: action,
      _add: action,
      _remove: action,
      _update: action,
      _lock: action,
      _unlock: action,
    });
  }

  get (key) {
    return this.records.get(key);
  }

  get size () {
    return this.records.size;
  }

  bind (socket) {
    this._socket = socket;
    this.refreshing = true;
    this._socket.emit('subscribe', this.name, this.companyid);
  }

  unbind () {
    if (!this.socket) return;
    this._socket.emit('unsubscribe', this.name, this.companyid);
  }

  refresh () {
    this.refreshing = true;
    this._socket.emit('sync', this.name, this.companyid);
  }

  emit (event, ...args) {

    event = '_' + event;
    if (typeof this[event] !== 'function') {
      warn('Attempted to emit an event that channels cannot receive', { name: this.name, event });
      return;
    }

    this[event](...args);
  }

  _reset (contents) {
    this.refreshing = false;
    const records = map(contents, (row) => {
      let record = this.records.get(row.id);
      if (record) record.merge(row);
      else record = new PipelineRecord(row);
      return [ row.id, record ];
    });
    this.records.replace(records);
  }

  _add (contents) {
    const records = map(contents, (row) => {
      let record = this.records.get(row.id);
      if (record) record.merge(row);
      else record = new PipelineRecord(row);
      return [ row.id, record ];
    });

    this.records.merge(records);
  }

  _remove (ids) {
    for (const id of ids) {
      this.records.delete(id);
    }
  }

  _update (contents) {
    contents.forEach((row) => {
      const record = this.records.get(row.id);
      if (record) record.merge(row);
      else this.records.set(row.id, new PipelineRecord(row));
    });
  }

  _lock (id, lock) {
    const record = this.records.get(id);
    if (!record) return;
    record.lock(lock);
  }

  _unlock (id, lock) {
    const record = this.records.get(id);
    if (!record) return;
    record.unlock(lock);
  }
}

const READS = [
  'entries', 'forEach', 'get', 'has', 'keys', 'values', Symbol.iterator,
];

for (const alias of READS) {
  DataChannel.prototype[alias] = function (...args) { return this.records[alias](...args); };
}
