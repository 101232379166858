import axios from 'axios';
import React, { useState } from 'react';
import getJWT from 'common/authentication/jwt';

export default class GlobalSearch {

    constructor () {

        const jwt = getJWT();

        const headers = {
            'x-noredirect':1
        };
        if (jwt) headers.Authorization = 'Bearer ' + jwt;

        this._axios = axios.create({
            baseURL : "/search/",
            timeout: 20000,
            headers,
        });

        this._pollerid = null;
    }

    async search ( term ) {

        await this._startSearch(term);

        return await this._pollResponses();
    }

    async _startSearch( term ){

        try {
            var start = await this._axios.get('/global/'+term);
            if(start.data.id !== undefined && start.data.id !== null)
            {
                this._pollerid = start.data.id;
            }
        } catch(e) {
            console.log(e);
        }
    }

    async _pollResponses ( ){

        var results = [];

        while( this._pollerid !== null && this._pollerid !== 'end')
        {
            var searchresults = await this._axios.get('/poller/'+this._pollerid);

            searchresults.data.events.forEach( (item) => {

                if(item[0] == "end")
                {
                    return;
                }

                const found = results.some(el => el.id === item[1].id && el.type === item[1].type);
                if (!found)
                    results.push(item[1]);

            })

            if(searchresults.data.events[searchresults.data.events.length-1] == "end")
            {
                this._pollerid = "end";
            }
        }

        return results;

    }

}