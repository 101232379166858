
import { PureComponent, createContext, useContext } from 'react';
import getJWT from 'common/authentication/jwt';

import PropTypes from 'prop-types';
import io from "socket.io-client/dist/socket.io.js";
import DataChannel from './datachannel';

export const PipelineDataContext = createContext(null);

const propTypes = {
  refreshInterval: PropTypes.number,
  companyid: PropTypes.number,
};

export class PipelineServerProvider extends PureComponent {

  companyid = 0;
  refreshTimer = null;
  channels = new Map;
  connected = false;
  subscriptions = {};

  constructor (...args) {

    super(...args);

    this.state = {
      'error': null,
    };

  }

  connect () {
    this.companyid = this.props.companyid;
    const jwt = getJWT();

    const socket = this.socket = io.connect('//' + document.location.hostname, {
      rememberTransport: false,
      transports: [ 'websocket', 'xhr-polling' ],
      'reconnection delay': 2000,
      'reconnection limit': 10000,
      'max reconnection attempts': 3,
      query: {
        token: jwt,
      },
    });

    socket.on('connect',       this.onConnect);
    socket.on('disconnect',    this.onDisconnect);
    socket.on('reset',         this.onReset);
    socket.on('added',         this.onAdded);
    socket.on('removed',       this.onRemoved);
    socket.on('changed',       this.onChanged);
    socket.on('locked',        this.onLocked);
    socket.on('unlocked',      this.onUnlocked);
    socket.on('connect_error', this.onError);
  }

  componentWillUnmount () {
    this.disconnect();
  }

  disconnect () {
    const socket = this.socket;
    this.socket = null;

    if (this.refreshTimer) clearInterval(this.refreshTimer);
    this.refreshTimer = null;

    if (!socket) return;

    socket.close();
    socket.off('connect',       this.onConnect);
    socket.off('disconnect',    this.onDisconnect);
    socket.off('reset',         this.onReset);
    socket.off('added',         this.onAdded);
    socket.off('removed',       this.onRemoved);
    socket.off('changed',       this.onChanged);
    socket.off('locked',        this.onLocked);
    socket.off('unlocked',      this.onUnlocked);
    socket.off('connect_error', this.onError);
  }

  subscribe = (channelName) => {
    if (!this.socket) this.connect();

    if (!this.subscriptions[channelName]) {
      this.subscriptions[channelName] = 1;
    } else this.subscriptions[channelName]++;

    if (this.channels.has(channelName)) return this.channels.get(channelName);

    const channel = new DataChannel(channelName, this.companyid);
    this.channels.set(channelName, channel);

    if (this.connected) {
      channel.bind(this.socket);
    }

    return channel;
  };

  unsubscribe = (channelName) => {
    if (this.subscriptions[channelName]) this.subscriptions[channelName]--;

    // still subscribed, nothing more to do
    if (this.subscriptions[channelName]) return;

    this.channels.get(channelName)?.unbind();
    this.channels.delete(channelName);
  };

  get = (channelName) => this.channels.get(channelName);
  
  refresh = () => {
    for (const channel of this.channels.values()) {
      channel.refresh();
    }
  };

  render () {
    const { subscribe, unsubscribe, get,refresh } = this;

    return (
      <PipelineDataContext.Provider value={{ subscribe, unsubscribe, get, refresh ,error: this.state.error }}>{this.props.children}</PipelineDataContext.Provider>
    );
  }

  
  lock = ({ channelName, id, level = 1 }) => {
    this.socket.emit('lock', {
      companyid: this.companyid,
      pipeline: channelName,
      id,
      level,
    });
  };

  unlock = ({ channelName, id }) => {
    this.socket.emit('unlock', {
      companyid: this.companyid,
      pipeline: channelName,
      id,
    });
  };

  onConnect = () => {
    this.connected = true;

    const { refreshInterval = 900000 } = this.props;

    for (const channelName of Object.keys(this.subscriptions)) {
      console.log("onConnect ...", channelName)
      if (!this.channels.has(channelName)) {
        this.channels.set(channelName, new DataChannel(channelName));
      }

      this.channels.get(channelName)?.bind(this.socket);
    }

    // setup resync timer
    if (this.refreshTimer) clearInterval(this.refreshTimer);
    this.refreshTimer = setInterval(this.refresh, refreshInterval);
    this.setState({ 'error': null });
  };

  onDisconnect = () => {
    this.connected = false;
  };

  onReset = (channelName, contents) => {
    this.channels.get(channelName)?.emit('reset', deserializeContents(contents));
  };

  onAdded = (channelName, contents) => {
    this.channels.get(channelName)?.emit('add', deserializeContents(contents));
  };
  onRemoved = (channelName, ids) => {
    this.channels.get(channelName)?.emit('remove', ids);
  };
  onChanged = (channelName, contents) => {
    console.log("onChanged ...", channelName, contents)
    this.channels.get(channelName)?.emit('update', deserializeContents(contents));
  };
  onLocked = (channelName, id, lock) => {
    this.channels.get(channelName)?.emit('lock', id, lock);
  };
  onUnlocked = (channelName, id) => {
    this.channels.get(channelName)?.emit('unlock', id);
  };
  onError = (error) => {

    this.setState({ error });

  };
}


PipelineServerProvider.displayName = 'PipelineServerProvider';
PipelineServerProvider.propTypes = propTypes;


export default function usePipelineData () {
  return useContext(PipelineDataContext) || {};
}

function deserializeContents (contents) {
  contents = Array.isArray(contents) ? contents : Object.values(contents);
  if (!contents.length) return [];
  contents = '[' + contents.join(',') + ']';
  try {
    contents = JSON.parse(contents);
  } catch (e) {
    throw new Error('Could not deserialize contents:' + e.message);
  }

  return contents;
}
