
import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { styled } from '@mui/material/styles';
import { cl, isUndefinedOrNull } from '@twipped/utils';


const ColumnRoot = styled('div', {
  name: 'VoxColumn',
  slot: 'Root',
})`
  display: flex;
  align-self: stretch;
  flex-flow: column nowrap;

  &.VoxColumn-wrap {
    flex-wrap: wrap;
  }

  &.VoxColumn-scrollable {
    overflow-x: hidden;
    overflow-y: auto;
  }

  &.VoxColumn-align-top {
    justify-content: flex-start;
  }

  &.VoxColumn-align-center {
    justify-content: center;
  }

  &.VoxColumn-align-bottom {
    justify-content: flex-end;
  }

  &.VoxColumn-align-between {
    justify-content: space-between;
  }

  &.VoxColumn-align-around {
    justify-content: space-around;
  }

  &.VoxColumn-align-stretch {
    justify-content: stretch;
  }

  &.VoxColumn-justify-left {
    align-items: flex-start;
  }

  &.VoxColumn-justify-center {
    align-items: center;
  }

  &.VoxColumn-justify-right {
    align-items: flex-end;
  }

  &.VoxColumn-justify-stretch {
    align-items: stretch;
  }
`;

const Column = forwardRef(function Column ({
  justify,
  align,
  className,
  style,
  component,
  scrollable = false,
  wrap = false,
  grow,
  shrink,
  basis,
  auto,
  fill,
  ...other
}, ref) {
  const Component = component || 'div';

  style = {
    ...style,
  };

  if (grow === true) grow = 1;
  if (grow === false) grow = 0;
  if (shrink === true) shrink = 1;
  if (shrink === false) shrink = 0;

  if (fill) {
    style.flex = `1 1 ${basis || 'auto'}`;
  } else {
    if (!isUndefinedOrNull(grow)) style.flexGrow = grow;
    if (!isUndefinedOrNull(shrink)) style.flexShrink = shrink;
    if (basis) style.flexBasis = basis;
    else if (auto) style.flexBasis = 'auto';
  }

  return (
    <ColumnRoot
      {...other}
      as={Component}
      ref={ref}
      className={cl(
        className,
        justify && `VoxColumn-justify-${justify}`,
        align && `VoxColumn-align-${align}`,
        scrollable && 'VoxColumn-scrollable',
        wrap && 'VoxColumn-wrap',
      )}
      style={style}
    />
  );
});
Column.propTypes = {
  component: PropTypes.elementType,

  wrap: PropTypes.bool,

  scrollable: PropTypes.bool,

  align: PropTypes.oneOf([
    'top',
    'center',
    'bottom',
    'between',
    'around',
    'stretch',
  ]),

  justify: PropTypes.oneOf([
    'left',
    'center',
    'right',
    'stretch',
  ]),

  grow: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.number,
  ]),

  shrink: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.number,
  ]),

  basis: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),

  auto: PropTypes.bool,

  fill: PropTypes.bool,
};

export default Column;
